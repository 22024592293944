import * as React from "react"
import { useState, useEffect, useMemo, useCallback } from "react"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import { IconSearch } from "../components/icons/Icons"
import client from "../helpers/AlgoliaClient"
import FilterPanel, { handleFacetClick } from "../components/products/FilterPanel"
import ProductGrid from "../components/products/ProductGrid"
import queryString from "query-string"
import { navigate } from "gatsby"
import IconsProductPreloader from "../components/products/IconsProductPreloader"

const SearchPage = ({ location }) => {
  const [page, setPage] = useState(0)
  const [hits, setHits] = useState([])
  const [facets, setFacets] = useState({})
  const [query, setQuery] = useState({
    hitsPerPage: 24,
    responseFields: "*",
    attributesToRetrieve: "*",
    attributesToSnippet: "*:20",
    snippetEllipsisText: "…",
    facets: ["*"],
  })
  const fixObjectOfString = useCallback(o => {
    if (!o) return o
    Object.keys(o).forEach(key => {
      if (!Array.isArray(o[key])) o[key] = [o[key]]
    })
    return o
  }, [])
  const initialFilters = useMemo(
    () => fixObjectOfString(queryString.parse(location.hash)),
    [location.hash]
  )
  const [facetFilters, setFacetFilters] = useState(initialFilters)
  const queryParameters = queryString.parse(location.search)
  const initSearchTerm = queryParameters && queryParameters["q"] ? queryParameters["q"] : ""
  const [keyword, setKeyword] = useState(initSearchTerm)
  const [searchTerm, setSearchTerm] = useState(initSearchTerm)
  const onSearch = useCallback(
    e => {
      e.preventDefault()
      window.history.pushState(keyword, "", "/search/?q=" + keyword)
      setSearchTerm(keyword)
    },
    [navigate, keyword]
  )

  useEffect(() => {
    let hash = window.location.hash
    if (hash) hash = hash.substr(1)
    setFacetFilters(filters => {
      let h = queryString.stringify(filters)
      if (hash !== h) {
        return fixObjectOfString(queryString.parse(location.hash))
      }
      return filters
    })
  }, [location.hash])

  const isOldFilter = useCallback((facet, filters, prevFacets) => {
    return filters && facet in filters && facet in (prevFacets || {})
  }, [])

  const mergeFacet = useCallback(
    (newFacets, facet, filters, prevFacets, searchResponse) => {
      if (isOldFilter(facet, filters, prevFacets)) {
        newFacets[facet] = prevFacets[facet]
      } else {
        newFacets[facet] = []
        for (let facetOption in searchResponse.facets[facet]) {
          if (!searchResponse.facets[facet].hasOwnProperty(facetOption)) continue
          if ((facetOption || "").length === 0) {
            continue
          }
          newFacets[facet].push({
            name: facetOption,
            count: searchResponse.facets[facet][facetOption],
          })
        }
      }
      if ((newFacets[facet] || []).length <= 1 && !(facet in filters)) {
        delete newFacets[facet]
      }
    },
    [isOldFilter]
  )

  const mergeFacets = useCallback(
    (prevFacets, filters, searchResponse) => {
      const newFacets = {}
      for (let facet in searchResponse.facets) {
        if (!searchResponse.facets.hasOwnProperty(facet)) continue
        mergeFacet(newFacets, facet, filters, prevFacets, searchResponse)
      }
      return newFacets
    },
    [mergeFacet]
  )

  useEffect(() => {
    window.location.hash = queryString.stringify(facetFilters)
    const ff = []
    if (facetFilters) {
      for (let key in facetFilters) {
        if (!Object.hasOwnProperty.bind(facetFilters)(key)) continue
        ff.push(facetFilters[key])
      }
    }
    const searchProps = {
      ...query,
      page: page,
      facetFilters: ff,
    }

    const searchClient = client()
    const index = searchClient.initIndex("production_products")
    let p = index.search(searchTerm, searchProps)
    p.then(searchResponse => {
      setFacets(prevFacets => mergeFacets(prevFacets, facetFilters, searchResponse))
      setHits(searchResponse.hits)
    })
  }, [searchTerm, query, page, facetFilters, mergeFacets])

  const handleFacet = useCallback(
    (key, facet) => {
      const filters = handleFacetClick(key, facet, facetFilters)
      setFacetFilters(filters)
      // this.refreshData({}, facetFilters)
    },
    [handleFacetClick, facetFilters, setFacetFilters]
  )

  const breadcrumbs = useMemo(() => [{ url: "search", name: "Search" }], [])

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Seo title={"Search " + searchTerm} canonical={"/search"} noindex={true} />
      <IconsProductPreloader />
      <div className="w-11/12 mx-auto max-w-[1366px]">
        <h2 className={"my-4"}>Search</h2>
        <form action={"/search"} method={"get"} onSubmit={onSearch}>
          <div className={"flex my-4 w-full border-b border-white justify-self-end"}>
            <label className={"sr-only"} htmlFor={"search-keywords"}>
              Search
            </label>
            <input
              type={"text"}
              name={"q"}
              placeholder={"Tell us, what are you looking for?"}
              value={keyword}
              id={"search-keywords"}
              onChange={e => {
                setKeyword(e.target.value)
              }}
              className={"flex-grow border border-accent px-4 placeholder-gray-500"}
            />
            <button className={"bg-accent text-white w-auto p-4"}>
              <IconSearch />
              <span className={"sr-only"}>Search</span>
            </button>
          </div>
        </form>
      </div>
      <div className="w-11/12 mx-auto max-w-[1366px]">
        <h1 className={"py-6"}>{searchTerm} search results</h1>
        <div className={"flex flex-col md:flex-row"}>
          <div className={"md:w-1/5 p-4 pl-0 flex-none"}>
            <FilterPanel facets={facets} facetFilters={facetFilters} handleFacet={handleFacet} />
          </div>
          <div className={"md:w-4/5 p-4 flex-none"}>
            <ProductGrid
              products={hits}
              page={page}
              setPage={setPage}
              setQuery={setQuery}
              query={query}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SearchPage
